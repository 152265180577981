import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent";
import { CallAPI } from "actions/General";
import { useNavigate } from "react-router-dom";
import CustomModal from "components/CustomModal";

function EnrollmentScheduleAction({ data, otherDetails }) {
  console.log("otherDetails",otherDetails)
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const enrollCourse = async () => {
    const result = await CallAPI(
      "CREATE_ENROLLMENT",
      {
        schedule_id: data.id,
        ...otherDetails,
      },
      null,
      setLoading,
      setApiError,
      null
    );

    if (result.status) {
      navigate("/enrollments");
    } else {
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="m-auto my-5">
      <ButtonComponent
        text="enroll"
        loading={loading}
        disabled={loading}
        onClick={enrollCourse}
        variant="orange-1"
      />
      {isModalOpen && (
        <CustomModal
          header={""}
          text={apiError}
          buttons={[
            {
              text: "ok",
              variant: "blue-1",
              onClick: "closeModal",
              loading: false,
              disabled: false,
            },
          ]}
          onClose={closeModal}
          variant={"error"}
          functionsList={{ closeModal }}
        />
      )}
    </div>
  );
}
EnrollmentScheduleAction.propTypes = {
  data: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
};
export default EnrollmentScheduleAction;
