import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CallAPI } from "actions/General";
import LoaderComponent from "components/LoaderComponent";
import GridView from "components/GridView";
import { GridModulesHeader } from "./utils";
import { useNavigate } from "react-router-dom";
function EnrollmentModulesUnitsList({
  enrollment_id,
  type,
  course_id,
  program_id,
  module_id,
  timeToStart = 0,
}) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [details, setDetails] = useState(null);
  const getAppropriateDetails = async () => {
    const apiName = "GET_COURSE_MODULES_LIST";
    const recordId =
      type === "course" ? course_id : type === "module" ? module_id : "";
    const recordType =
      type === "course" ? "module" : type === "module" ? "unit" : "";
    const result = await CallAPI(
      apiName,
      { id: recordId, type: recordType.toUpperCase() },
      null,
      setLoader,
      null,
      null
    );
    if (result.status) {
      const data = result.data;
      setDetails(data);
    }
  };
  useEffect(() => {
    getAppropriateDetails();
  }, []);
  return (
    <>
      {loader && <LoaderComponent classes="" />}
      {details && (
        <GridView
          data={details}
          columns={GridModulesHeader}
          options={{
            dataToSend: { enrollment_id, course_id, getAppropriateDetails },
            type: `${timeToStart > 0 ? "" : "enrollment"}`,
          }}
          type={"type-1 type-2"}
        />
      )}
    </>
  );
}
EnrollmentModulesUnitsList.propTypes = {
  enrollment_id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  course_id: PropTypes.string,
  program_id: PropTypes.string,
  module_id: PropTypes.string,
  timeToStart: PropTypes.number,
};
export default EnrollmentModulesUnitsList;
