import React, { useEffect, useState, version } from "react";
import ErrorComponent from "components/ErrorComponent";
import GridView from "components/GridView";
import ListingHeaderComponent from "components/ListingHeaderComponent";
import LoaderComponent from "components/LoaderComponent";
import PaginationComponent from "components/PaginationComponent";
import { ScheduleGridHeader } from "./utils";
import { DefaultPaginationSize } from "config/config";
import { CallAPI } from "actions/General";
import { useLocation } from "react-router-dom";
import CustomModal from "components/CustomModal";

function EnrollmentSchedule() {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [apiError, setApiError] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(DefaultPaginationSize);

  const getAllSchedules = async (params) => {
    console.log("params", params);
    setLoaderType("get_listing");
    const bodyObj = {
      relations: ["season", "teacher", "course", "program"],
      page: page,
      pageSize: limit,
      ...(params.type === "course"
        ? { course_id: params.course_id }
        : params.type === "program"
        ? { program_id: params.program_id }
        : { event_id: params.event_id }),
    };
    console.log("bodyObj", bodyObj);
    const result = await CallAPI(
      "FETCH_SCHEDULE_LIST",
      bodyObj,
      null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setSchedules(result.data.schedule);
      setTotalRecords(result.data.total);
    }
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.version_id &&
      location.state.version_id !== ""
    ) {
      console.log("location.state", location.state);
      getAllSchedules(location.state);
    }
  }, [location]);

  return (
    <>
      {loader && loaderType === "get_listing" && (
        <LoaderComponent classes={"mx-4"} />
      )}
      <div className="card">
        {apiError && <ErrorComponent text={apiError} variant="danger" />}
        <ListingHeaderComponent
          type={"course_schedule_listing"}
          title={"Test Course"}
          setDisplayType={() => {}}
          displayType={""}
          addItem={""}
        />
        <hr />
      </div>
      <div className="bg-white mb-4">
        <GridView
          data={schedules}
          columns={ScheduleGridHeader}
          options={{
            dataToSend: {
              version_id: location.state.version_id,
              payment_type: location.state.payment_type.type,
              subscription_slug:
                location.state.payment_type.type === "subscription"
                  ? location.state.payment_type.slug
                  : "",
            },
            type: "schedule",
          }}
          classes="mt-0"
          type={"type-1 type-2"}
        />
      </div>
      <PaginationComponent
        totalPages={
          totalRecords ? Math.ceil(totalRecords / limit) : totalRecords
        }
        pageLimit={limit}
        setPageLimit={setLimit}
        setPage={setPage}
        currentPage={page}
      />
    </>
  );
}

export default EnrollmentSchedule;
