import ButtonComponent from "components/ButtonComponent";
import TabsComponent from "components/TabsComponent";
import React, { useEffect, useState } from "react";
import { AddScheduleTabs, ModalButtons } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import { handlePageResize } from "config/config";
import { CallAPI } from "actions/General";
import {
  BasicDetailsSchema,
  AccessSchema,
  ScheduleSchema,
} from "./FieldValidationRoles";
import LoaderComponent from "components/LoaderComponent";
import BasicDetails from "./BasicDetails";
import ErrorComponent from "components/ErrorComponent";
import CustomModal from "components/CustomModal";
import AccessDetails from "./AccessDetail";
import SelectScheduleDetails from "./SelectSchedule";
import moment from "moment";
import Preview from "./Preview";
function AddSchedule() {
  const location = useLocation();
  const navigate = useNavigate();

  const [listingDetails, setListingDetails] = useState({
    auto_generate_link: [],
    link_settings: [],
    auto_upload_session: [],
    type: [],
    users: [],
    programs: [],
    courses: [],
    departments: [],
    profiles: [],
    roles: [],
    seasons: [],
    program_courses_schedules: [],
  });

  const [selectedTab, setSelectedTab] = useState(1);
  const [updatedRecordId, setUpdatedRecordId] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [ApiError, setApiError] = useState("");
  const [values, setValues] = useState({
    title: "",
    teacher: "",
    auto_generate_link: "",
    link_setting: "",
    auto_upload_session: "",
    type: "",
    program_id: "",
    course_id: "",
    description: "",
    department: "",
    description: "",
    role: "",
    profile: "",
    year: null,
    season_id: "",
    schedule_date: null,
    cancellation_date: null,
    duration: null,
    duration_type: "",
    enrollment_end_date: null,
    courses_schedules: [],
  });

  const [errors, setErrors] = useState({});
  const [rowItems, setRowItems] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");

  const getScheduleDetails = async () => {
    if (updatedRecordId) {
      setLoaderType("get_details");
      setLoader(true);
      let result = await CallAPI(
        "GET_SCHEDULE_DETAIL",
        null,
        updatedRecordId,
        null,
        null,
        null
      );
      if (result.status) {
        let scheduleObj = result.data;
        let prevValues = {
          title: scheduleObj.title,
          teacher: scheduleObj.teacher.id,
          auto_generate_link: scheduleObj.auto_generate_link ? "1" : "0",
          link_setting: scheduleObj.link_setting,
          auto_upload_session: scheduleObj.auto_upload_session ? "1" : "0",
          type: scheduleObj.type,
          program_id:
            scheduleObj.type === "PROGRAM" ? scheduleObj.program_id : "",
          course_id: scheduleObj.type === "COURSE" ? scheduleObj.course.id : "",
          description: scheduleObj.description,
          department: scheduleObj.department,
          description: scheduleObj.description,
          role: scheduleObj.role,
          profile: scheduleObj.profile,
          year: scheduleObj.year,
          season_id: scheduleObj.season ? scheduleObj.season.id : "",
          schedule_date: scheduleObj.schedule_date
            ? scheduleObj.schedule_date * 1000
            : "",
            cancellation_date: scheduleObj.cancellation_date
            ? scheduleObj.cancellation_date * 1000
            : "",
          duration: scheduleObj.duration,
          duration_type: scheduleObj.duration_type,
          enrollment_end_date: scheduleObj.enrollment_end_date
            ? scheduleObj.enrollment_end_date * 1000
            : "",
          courses_schedules:
            scheduleObj.type === "program" ? scheduleObj.course_schedules : [],
          schedule: scheduleObj,
        };
        console.log("prevValues", prevValues);
        setValues({
          ...prevValues,
        });
      }
      await getOtherSystemDetails();
      setLoader(false);
    }
  };

  const getRequiredDataForForm = async () => {
    const details = {
      auto_generate_link: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "1",
          displayValue: "Yes",
        },
        {
          value: "0",
          displayValue: "No",
        },
      ],
      link_settings: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "Developer Zoom",
          displayValue: "Developer Zoom",
        },
      ],
      auto_upload_session: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "1",
          displayValue: "Yes",
        },
        {
          value: "0",
          displayValue: "No",
        },
      ],
      type: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "COURSE",
          displayValue: "Course",
        },
        // {
        //   value: "PROGRAM",
        //   displayValue: "Program",
        // },
      ],
    };

    setListingDetails((prevDetails) => ({
      ...prevDetails,
      ...details,
    }));
  };
  const fetchProgramAndCourses = async () => {
    const apiName =
      values.type === "COURSE" ? "FETCH_COURSES_LIST" : "FETCH_PROGRAMS_LIST";
    const responseKey = values.type === "COURSE" ? "courses" : "programs";
    const dataList = await CallAPI(
      apiName,
      { is_active: true },
      null,
      null,
      null,
      null
    );
    if (dataList.status) {
      const allItems = dataList.data[responseKey].map((singleItem) => {
        return {
          value:
            values.type === "COURSE"
              ? singleItem.course_id
              : singleItem.program_id,
          displayValue: singleItem.title,
        };
      });
      const nullItem = { value: null, displayValue: "Select" };
      setListingDetails((prevDetails) => ({
        ...prevDetails,
        [responseKey]: [nullItem, ...allItems],
      }));
    }
  };
  useEffect(() => {
    if (
      (values.type === "COURSE" && listingDetails.courses?.length === 0) ||
      (values.type === "PROGRAM" && listingDetails.programs?.length === 0)
    ) {
      fetchProgramAndCourses();
    }
  }, [values.type]);

  const getOtherSystemDetails = async () => {
    try {
      const { status, data } = await CallAPI(
        "FETCH_DETAILS_FOR_SCHEDULE",
        {},
        null,
        null,
        null,
        null
      );
      if (!status) return;

      const defaultItem = { value: "", displayValue: "Select" };

      setListingDetails((prevDetails) => ({
        ...prevDetails,
        departments: [defaultItem, ...data.departments],
        profiles: [defaultItem, ...data.profiles],
        roles: [defaultItem, ...data.roles],
        users: [defaultItem, ...data.users],
      }));
      await getSeasons();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!location.state || !location.state.update_id) {
      getOtherSystemDetails();
    }
  }, []);
  const getSeasons = async () => {
    try {
      const { status, data } = await CallAPI("FETCH_SEASONS_LIST", {});

      if (status) {
        const seasons = [
          { value: null, displayValue: "Select" },
          ...(data?.map((item) => ({
            value: item.id,
            displayValue: item.title,
            startDate: item.start_date * 1000,
            endDate: item.end_date * 1000,
          })) || []),
        ];

        setListingDetails((prevDetails) => ({ ...prevDetails, seasons }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchProgramAllCourses = async () => {
    const dataList = await CallAPI(
      "GET_COURSES_SCHEDULES",
      { schedule_date: moment(values.schedule_date).format("DD-MM-YYYY") },
      values.program_id,
      null,
      null,
      null
    );
    if (dataList.status) {
      const allItems = dataList.data.courses.map((singleItem) => {
        return {
          course_id: singleItem.course_id,
          course_title: singleItem.title,
          course_schedules: singleItem.schedules,
        };
      });
      setListingDetails((prevDetails) => ({
        ...prevDetails,
        program_courses_schedules: allItems,
      }));
    }
  };
  useEffect(() => {
    if (
      values.schedule_date &&
      values.type === "PROGRAM" &&
      values.program_id
    ) {
      fetchProgramAllCourses();
    }
  }, [values.schedule_date]);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.update_id &&
      location.state.update_id !== ""
    )
      setUpdatedRecordId(location.state.update_id);

    getRequiredDataForForm();
  }, [location]);
  useEffect(() => {
    if (updatedRecordId !== "");
    getScheduleDetails();
  }, [updatedRecordId]);

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const updateFormMultipleValues = (newValues) => {
    setValues({ ...values, ...newValues });
  };
  const previousClick = () => {
    if (selectedTab === 1) {
      navigate("/schedule");
    } else {
      setSelectedTab(selectedTab - 1);
    }
  };

  const selectOtherTab = async (tabToSelect) => {
    let startTab = 1;
    try {
      while (startTab < tabToSelect) {
        const errorValidatorSchema =
          startTab === 1
            ? BasicDetailsSchema
            : startTab === 2
            ? AccessSchema
            : startTab === 3
            ? ScheduleSchema
            : null;
        if (errorValidatorSchema)
          await errorValidatorSchema.validate(values, { abortEarly: false });
        startTab++;
      }
      setSelectedTab(startTab);
    } catch (error) {
      const schemaErrors = {};
      error.inner?.forEach((err) => {
        schemaErrors[err.path] = err.message;
      });
      setErrors(schemaErrors);
      setSelectedTab(startTab);
    }
  };
  const nextClick = async () => {
    if (selectedTab === AddScheduleTabs.length) {
      submitForm();
    } else {
      try {
        setErrors({});
        const errorValidatorSchema =
          selectedTab === 1
            ? BasicDetailsSchema
            : selectedTab === 2
            ? AccessSchema
            : selectedTab === 3
            ? ScheduleSchema
            : null;

        if (errorValidatorSchema)
          await errorValidatorSchema.validate(values, { abortEarly: false });

        setSelectedTab(selectedTab + 1);
      } catch (error) {
        console.log(error);

        console.log("error", error);
        const schemaErrors = {};
        error.inner?.forEach((err) => {
          schemaErrors[err.path] = err.message;
        });
        console.log(schemaErrors);

        setErrors(schemaErrors);
      }
    }
  };
  const submitForm = async () => {
    setLoaderType("submit_form");
    console.log("values", values);
    const scheduleDetail = {
      title: values.title,
      teacher_id: values.teacher,
      auto_generate_link: values.auto_generate_link.toString() === "1",
      link_setting: values.link_setting,
      auto_upload_session: values.auto_upload_session.toString() === "1",
      type: values.type,
      program_id: values.type === "PROGRAM" ? values.program_id : null,
      course_id: values.type === "COURSE" ? values.course_id : null,
      description: values.description,
      department: parseInt(values.department),
      profile: parseInt(values.profile),
      role: parseInt(values.role),
      year: parseInt(values.year),
      season_id: values.season_id,
      duration: parseInt(values.duration),
      duration_type: values.duration_type,
      schedule_date: parseInt(new Date(values.schedule_date).getTime() / 1000),
      cancellation_date: parseInt(new Date(values.cancellation_date).getTime() / 1000),
      enrollment_end_date: parseInt(
        new Date(values.enrollment_end_date).getTime() / 1000
      ),
      courses_schedules: [],
    };
    const APIName =
      updatedRecordId && updatedRecordId !== ""
        ? "UPDATE_SCHEDULE"
        : "ADD_SCHEDULE";
    let result = await CallAPI(
      APIName,
      scheduleDetail,
      updatedRecordId && updatedRecordId !== "" ? updatedRecordId : null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType(
        updatedRecordId && updatedRecordId !== ""
          ? "recordUpdate"
          : "recordCreated"
      );
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/schedule");
  };
  function handleResize() {
    const itemsInSingleRow = handlePageResize();
    setRowItems(itemsInSingleRow);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {loader && loaderType === "get_details" && <LoaderComponent classes="" />}
      <div className="card">
        <div
          className="card-body custom-form"
          id="custom-form"
          style={{ "--noOfColumn": rowItems }}
        >
          {rowItems && (
            <>
              {ApiError && <ErrorComponent variant="error" text={ApiError} />}
              <TabsComponent
                tabs={AddScheduleTabs}
                selectedTab={selectedTab}
                setSelectedTab={selectOtherTab}
                // type={"start"}
              >
                <div className="mx-auto w-100 pt-15 pb-10 form-details">
                  {selectedTab === 1 ? (
                    <BasicDetails
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      otherDetails={{ ...listingDetails }}
                    />
                  ) : selectedTab === 2 ? (
                    <AccessDetails
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      otherDetails={{ ...listingDetails }}
                    />
                  ) : selectedTab === 3 ? (
                    <SelectScheduleDetails
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      updateFormMultipleValues={updateFormMultipleValues}
                      otherDetails={{ ...listingDetails }}
                    />
                  ) : selectedTab === 4 ? (
                    <Preview
                      values={values}
                      otherDetails={{ ...listingDetails }}
                    />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-auto d-flex justify-content-end gap-3 px-3">
                  <ButtonComponent
                    text={selectedTab === 1 ? "cancel" : "previous"}
                    onClick={previousClick}
                    variant="grey-1"
                    loading={false}
                    disabled={false}
                  />
                  <ButtonComponent
                    text={
                      selectedTab === AddScheduleTabs.length &&
                      updatedRecordId === ""
                        ? "submit"
                        : selectedTab === AddScheduleTabs.length &&
                          updatedRecordId !== ""
                        ? "update"
                        : "next"
                    }
                    onClick={nextClick}
                    variant="blue-1"
                    loading={loader && loaderType === "submit_form"}
                    disabled={loader && loaderType === "submit_form"}
                  />
                </div>
              </TabsComponent>
            </>
          )}
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal }}
        />
      )}
    </>
  );
}

export default AddSchedule;
