import React, { useState } from "react";
import PropTypes from "prop-types";
import ButtonComponent from "components/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "actions/General";
function EnrollmentModuleUnitActions({ data, otherDetails }) {
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");
  const navigate = useNavigate();
  const viewUnits = () => {
    const { getAppropriateDetails, ...details } = otherDetails;
    navigate("/enrollment_units", {
      state: { ...details, module_id: data.version_id, record_id: data.id },
    });
  };
  const viewNotesDocument = () => {
    const { getAppropriateDetails, ...details } = otherDetails;
    navigate("/enrollment_notes", {
      state: {
        record_id: data.id,
        record_type: data.type === "MODULE" ? "module" : "unit",
      },
    });
  };
  const openModuleUnit = async () => {
    const result = await CallAPI(
      "START_MODULE_UNIT",
      {},
      data.id,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      otherDetails.getAppropriateDetails();
    }
  };
  return (
    <div className="d-flex flex-column align-items-center my-8 gap-3">
      {data.status === "NOT_STARTED" ? (
        <ButtonComponent
          text={`${data.type === "MODULE" ? "open_module" : "open_unit"}`}
          variant={`${
            !data.has_in_progress && data.sequence === data.completed_items + 1
              ? "blue-1"
              : "grey-21"
          }`}
          loading={false}
          disabled={false}
          onClick={() => {
            if (
              !data.has_in_progress &&
              data.sequence === data.completed_items + 1
            ) {
              openModuleUnit();
            }
          }}
        />
      ) : (
        <>
          <ButtonComponent
            text="view_notes_documents"
            variant="white-blue-10 underline_with_column"
            loading={false}
            disabled={false}
            onClick={viewNotesDocument}
          />
          {data.type === "MODULE" && (
            <ButtonComponent
              text="view_units"
              variant="white-blue-10 underline_with_column"
              loading={false}
              disabled={false}
              onClick={viewUnits}
            />
          )}
        </>
      )}
    </div>
  );
}
EnrollmentModuleUnitActions.propTypes = { data: PropTypes.object.isRequired };
export default EnrollmentModuleUnitActions;
