import React from "react";
import PropTypes from "prop-types";
import { populateDate, populateDateTime } from "actions/TableUtils";

function Preview({ values, otherDetails }) {
  return (
    <div className="preview schedule">
      <div className="item">
        <span className="text-gray-800 fw-bold">Title:</span>
        <span className="text-gray-800">{values.title}</span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold ">Teacher:</span>
        <span className="text-gray-800">
          {
            otherDetails.users.filter(
              (singleUser) => singleUser.value === values.teacher
            )[0].displayValue
          }
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">
          Auto Generated Meeting Link:
        </span>
        <span className="text-gray-800">
          {values.auto_generate_link === "1" ? "True" : "False"}
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Link Settings:</span>
        <span className="text-gray-800">{values.link_setting}</span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">
          Auto Upload Session From Zoom:
        </span>
        <span className="text-gray-800">
          {values.auto_upload_session === "1" ? "True" : "False"}
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Type:</span>
        <span className="text-gray-800">{values.type}</span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">
          {values.type === "COURSE" ? "Courses" : "Program"} Name:
        </span>
        <span className="text-gray-800">
          {values.type === "COURSE"
            ? otherDetails.courses.filter(
                (singleCourse) => singleCourse.value === values.course_id
              )[0].displayValue
            : otherDetails.programs.filter(
                (singleProgram) => singleProgram.value === values.program_id
              )[0].displayValue}
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Department:</span>
        <span className="text-gray-800">
          {
            otherDetails.departments.filter(
              (singleDepartment) =>
                singleDepartment.value.toString() ===
                values.department.toString()
            )[0].displayValue
          }
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Role:</span>
        <span className="text-gray-800">
          {
            otherDetails.roles.filter(
              (singleRole) =>
                singleRole.value.toString() === values.role.toString()
            )[0].displayValue
          }
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Profile:</span>
        <span className="text-gray-800">
          {
            otherDetails.profiles.filter(
              (singleProfile) =>
                singleProfile.value.toString() === values.profile.toString()
            )[0].displayValue
          }
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Schedule Date:</span>
        <span className="text-gray-800">
          {populateDateTime(parseInt(values.schedule_date / 1000))}
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Season:</span>
        <span className="text-gray-800">
          {
            otherDetails.seasons.filter(
              (singleSeason) => singleSeason.value === values.season_id
            )[0].displayValue
          }
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Duration:</span>
        <span className="text-gray-800">
          {values.duration + " " + values.duration_type}
        </span>
      </div>
      <div className="item">
        <span className="text-gray-800 fw-bold">Enrollment End Date:</span>
        <span className="text-gray-800">
          {populateDate(parseInt(values.enrollment_end_date / 1000))}
        </span>
      </div>
    </div>
  );
}
Preview.propTypes = {
  values: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
};
export default Preview;
