import { Routes, Route, BrowserRouter } from "react-router-dom";
import NonAuthenticatedLayout from "layouts/NonAuthenticatedLayout";
import Login from "views/auth/Login";
import AuthenticatedLayout from "layouts/AuthenticatedLayout";
import SuperAdminLogin from "views/auth/SuperAdminLogin";
import Dashboard from "views/dashboard/Dashboard";
import ChooseBusiness from "views/auth/ChooseBusiness";
import Users from "views/users/Users";
import "./axios/Axios";
import Units from "views/units/Units";
import AddUnit from "views/units/AddUnit";
import LoginWithKeys from "views/auth/LoginWithKeys";
import SetPassword from "views/auth/SetPassword";
import Tags from "views/tags/Tags";
import Categories from "views/categories/Categories";
import Profile from "views/profile/Profile";
import Modules from "views/modules/Modules";
import AddModule from "views/modules/AddModule";
import Subscriptions from "views/subscriprions/Subscriptions";
import AddSubscription from "views/subscriprions/AddSubscription";
import SubscriptionResponse from "views/subscriprions/SubscriptionResponse";
import Settings from "views/settings/Settings";
import Program from "views/program/Program";
import Course from "views/course/Course";
import AddCourse from "views/course/AddCourse";
import CourseDetailView from "views/course/CourseDetailView";
import UnitDetailView from "views/units/UnitDetailView";
import ModuleDetailView from "views/modules/ModuleDetailView";
import CourseModules from "views/course/CourseModules";
import ModuleUnits from "views/modules/ModuleUnits";
import ErrorPage from "views/ErrorPage";
import Schedule from "views/schedule/Schedule";
import AddProgram from "views/program/AddProgram";
import ProgramDetailView from "views/program/ProgramDetailView";
import ProgramCoursesList from "views/program/ProgramCoursesList";
import AddSchedule from "views/schedule/AddSchedule";
import Seasons from "views/seasons/Seasons";
import Enrollments from "views/enrollments/Enrollments";
import EnrollmentDetails from "views/enrollments/EnrollmentDetails";
import EnrollmentSchedule from "views/enrollments/EnrollmentSchedule";
import EnrollmentModulesList from "views/enrollments/EnrollmentModulesList";
import EnrollmentUnitsList from "views/enrollments/EnrollmentUnitsList";
import EnrollmentNotes from "views/enrollments/EnrollmentNotes";
import EnrollmentProgress from "views/enrollments/EnrollmentProgress";

function App() {
  return (
    // <div className="App">
    <BrowserRouter>
      <Routes>
        <Route element={<NonAuthenticatedLayout />}>
          <Route path="/" element={<Login />} />
          <Route
            path="/login_with_keys/:public_key/:private_key/:module_id"
            element={<LoginWithKeys />}
          />
          <Route path="/password" element={<SetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<SetPassword />} />
          <Route path="/super_admin" element={<SuperAdminLogin />} />
          <Route path="/choose_business" element={<ChooseBusiness />} />
        </Route>
        <Route element={<AuthenticatedLayout />}>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/users" element={<Users />} />

          <Route path="/program" element={<Program />} />
          <Route path="/add_program" element={<AddProgram />} />
          <Route path="/edit_program" element={<AddProgram />} />
          <Route path="/program_detail" element={<ProgramDetailView />} />
          <Route path="/program_courses" element={<ProgramCoursesList />} />

          <Route path="/course" element={<Course />} />
          <Route path="/add_course" element={<AddCourse />} />
          <Route path="/edit_course" element={<AddCourse />} />
          <Route path="/course_detail" element={<CourseDetailView />} />
          <Route path="/course_modules" element={<CourseModules />} />

          <Route path="/enrollments" element={<Enrollments />} />
          <Route path="/enroll_schedules" element={<EnrollmentSchedule />} />
          <Route path="/enrollment_detail" element={<EnrollmentDetails />} />
          <Route
            path="/enrollment_modules"
            element={<EnrollmentModulesList />}
          />
          <Route path="/enrollment_units" element={<EnrollmentUnitsList />} />
          <Route path="/enrollment_notes" element={<EnrollmentNotes />} />
          <Route path="/enrollment_progress" element={<EnrollmentProgress />} />

          <Route path="/modules" element={<Modules />} />
          <Route path="/add_module" element={<AddModule />} />
          <Route path="/edit_module" element={<AddModule />} />
          <Route path="/module_detail" element={<ModuleDetailView />} />
          <Route path="/module_units" element={<ModuleUnits />} />

          <Route path="/units" element={<Units />} />
          <Route path="/add_unit" element={<AddUnit />} />
          <Route path="/edit_unit" element={<AddUnit />} />
          <Route path="/unit_detail" element={<UnitDetailView />} />

          <Route path="/schedule" element={<Schedule />} />
          <Route path="/add_schedule" element={<AddSchedule />} />
          <Route path="/edit_schedule" element={<AddSchedule />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/categories" element={<Categories />} />

          <Route path="/seasons" element={<Seasons />} />

          <Route path="/subscription" element={<Subscriptions />} />
          <Route path="/add_subscription" element={<AddSubscription />} />
          <Route
            path="/subscription_response/:type"
            element={<SubscriptionResponse />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
    // </div>
  );
}

export default App;
