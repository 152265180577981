import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoaderComponent from "components/LoaderComponent";
import { getImagePreviewUrl } from "config/config";
import { CallAPI } from "actions/General";
import DEFAULT_IMG from "assets/images/general/default-image.svg";
import COURSE_TICK from "assets/images/general/course-tick.svg";
import DisplayHtml from "views/DisplayHtml";
import ButtonComponent from "components/ButtonComponent";
import allFunction from "actions/TableUtils";
import { ViewProgramDetailTabs } from "./utils";
import TabsComponent from "components/TabsComponent";

function ProgramDetailView() {
  const location = useLocation();

  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(true);
  const [loaderType, setLoaderType] = useState("get_list");
  const [selectedTab, setSelectedTab] = useState(1);
  const [imageUrl, setImageUrl] = useState("");

  const getCourseDetails = async (record_id) => {
    if (record_id) {
      setLoaderType("get_list");
      let result = await CallAPI(
        "GET_PROGRAM_DETAIL",
        null,
        record_id,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let courseObj = result.data;
        let prevValues = {
          title: courseObj.title,
          subscription: courseObj.subscription
            ? courseObj.subscription.title
            : "",
          banner: courseObj.banner_image ? courseObj.banner_image.id : null,
          reward_credits: courseObj.reward_credits,
          enroll_credits: courseObj.enroll_credits,
          category_name: courseObj.category.title,
          language: courseObj.language,
          slug: courseObj.slug,
          status: courseObj.status,
          detail: courseObj.description,
          is_free: courseObj.is_free ? "1" : "0",
          short_description: courseObj.short_description,
          completion_duration: courseObj.completion_duration,
          pre_req_courses: courseObj.pre_req_courses
            ? courseObj.pre_req_courses
            : [],
          compulsory_courses: courseObj.compulsory_courses
            ? courseObj.compulsory_courses
            : [],
          elective_courses: courseObj.elective_courses
            ? courseObj.elective_courses
            : [],
          quiz_url: courseObj.quiz_url,
          completed_percentage:
            courseObj.certificate_creteria.completed_percentage,
          quiz_percentage: courseObj.certificate_creteria.quiz_percentage,
          validity_period: courseObj.certificate_creteria.validity_period,
          validity_type: courseObj.certificate_creteria.validity_type,
          cert_description: courseObj.certificate_creteria.description,
          payment_type: courseObj.payment_type,
          author_name: courseObj.author_name,
          tags: courseObj.tags.map((singleTag) => {
            return singleTag.title;
          }),
          learning_points: courseObj.learning_points.map(
            (singleLearningPoint) => {
              return singleLearningPoint.title;
            }
          ),
        };
        setValues({
          ...prevValues,
        });
        if (prevValues.banner) {
          getPreviewImg(prevValues.banner);
        }
      }
    }
  };
  const getPreviewImg = async (id) => {
    const url = await getImagePreviewUrl(id, null);
    setImageUrl(url);
  };
  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.record_id &&
      location.state.record_id !== ""
    )
      getCourseDetails(location.state.record_id);
  }, [location]);

  const publishProgram = async () => {
    setLoaderType("publish");
    const result = await CallAPI(
      "PUBLISH_PROGRAM",
      {
        status: "PUBLISHED",
      },
      location.state.record_id,
      setLoader,
      null,
      null
    );
    if (result.status) getCourseDetails(location.state.record_id);
  };

  return (
    <>
      {loader && loaderType === "get_list" ? (
        <LoaderComponent classes="mx-4" />
      ) : (
        <div className="module-detail-view preview">
          <div className="p-5 pt-18 position-relative">
            <div className="item basic p-5">
              <img
                src={values.banner ? imageUrl : DEFAULT_IMG}
                alt="Unit Image"
              />
              <div className="top">
                <div>
                  <span className="text-gray-800 fw-bold">Title:</span>
                  <span className="text-gray-800">{values.title}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Category:</span>
                  <span className="text-gray-800">{values.category_name}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">language:</span>
                  <span className="text-gray-800">{values.language}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Author Name:</span>
                  <span className="text-gray-800">{values.author_name}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Subscription:</span>
                  <span className="text-gray-800">{values.subscription}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Tags:</span>
                  <span className="text-gray-800">
                    {values.tags.join(", ")}
                  </span>
                </div>
              </div>
              <div className="top">
                <div>
                  <span className="text-gray-800 fw-bold">Status:</span>
                  <span className="text-gray-800">
                    {allFunction.displayUnitStatus(values.status)}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Enroll Credits:</span>
                  <span className="text-gray-800">{values.enroll_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Reward Credits:</span>
                  <span className="text-gray-800">{values.reward_credits}</span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Payment Type:</span>
                  <span className="text-gray-800">
                    {allFunction.getSubscriptionType(values.payment_type)}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">is Free:</span>
                  <span className="text-gray-800">
                    {values.is_free ? "True" : "False"}
                  </span>
                </div>
                <div>
                  <span className="text-gray-800 fw-bold">Slug:</span>
                  <span className="text-gray-800">{values.slug}</span>
                </div>
              </div>
            </div>
            <div className="actions-buttons">
              <div>
                <ButtonComponent
                  text="publish_program"
                  onClick={publishProgram}
                  variant="blue-1"
                  loading={loader && loaderType === "publish"}
                  disabled={
                    allFunction.isItemPublished(values.status) ||
                    (loader && loaderType === "publish")
                  }
                />
              </div>
            </div>
          </div>
          <div className="p-5">
            <TabsComponent
              tabs={ViewProgramDetailTabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              type={"start"}
            >
              <div className="p-5 tab-item-detail item">
                {selectedTab === 1 ? (
                  <>
                    <span className="text-gray-800 fw-bold">
                      Short Description
                    </span>
                    <span className="text-gray-800">
                      {values.short_description}
                    </span>
                    <hr />
                    <span className="text-gray-800 fw-bold">Description</span>
                    <span className="text-gray-800">
                      {<DisplayHtml html={values.detail} />}
                    </span>
                  </>
                ) : selectedTab === 2 ? (
                  <ul>
                    {values.learning_points.map(
                      (singleLearningPoint, index) => {
                        return (
                          <li className="text-gray-800" key={index}>
                            {singleLearningPoint}
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : selectedTab === 3 ? (
                  <div className="item courses">
                    <div>
                      <span className="text-gray-800 fw-bold">
                        Compulsory Courses
                      </span>
                      <ul className="d-flex flex-column gap-3">
                        {values.compulsory_courses.map(
                          (singleCourse, index) => {
                            return (
                              <li className="text-gray-800" key={index}>
                                <img src={COURSE_TICK} alt="tick" />
                                {singleCourse.title}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                    <div>
                      <span className="text-gray-800 fw-bold">
                        Elective Courses
                      </span>
                      <ul className="d-flex flex-column gap-3">
                        {values.elective_courses.map((singleCourse, index) => {
                          return (
                            <li className="text-gray-800" key={index}>
                              <img src={COURSE_TICK} alt="tick" />
                              {singleCourse.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <span className="text-gray-800 fw-bold">
                        Pre Requisite Courses
                      </span>
                      <ul className="d-flex flex-column gap-3">
                        {values.pre_req_courses.map((singleCourse, index) => {
                          return (
                            <li className="text-gray-800" key={index}>
                              <img src={COURSE_TICK} alt="tick" />
                              {singleCourse.title}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <>Enrollment details</>
                )}
              </div>
            </TabsComponent>
          </div>
        </div>
      )}
    </>
  );
}

export default ProgramDetailView;
