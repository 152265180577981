import ButtonComponent from "components/ButtonComponent";
import TabsComponent from "components/TabsComponent";
import React, { useEffect, useState } from "react";
import { AddCourseTabs, ModalButtons } from "./utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getArrayDifference,
  handlePageResize,
  UploadMedia,
} from "config/config";
import { CallAPI } from "actions/General";
import {
  BasicDetailsSchema,
  CertificationSchema,
  NotesModulesSchema,
  TagsLearnHighlightsSchema,
} from "./FieldValidationRoles";
import LoaderComponent from "components/LoaderComponent";
import BasicDetails from "./BasicDetails";
import TagsLearnHighlights from "./TagsLearnHighlights";
import NotesModules from "./NotesModules";
import Preview from "./Preview";
import PreReqCourses from "./PreReqCourses";
import Certification from "./Certification";
import ErrorComponent from "components/ErrorComponent";
import CustomModal from "components/CustomModal";

function AddCourse() {
  const location = useLocation();
  const navigate = useNavigate();

  const [listingDetails, setListingDetails] = useState({
    subscriptions: [],
    categories: [],
    programs: [],
    isFree: [],
    paymentType: [],
  });
  const [selectedTab, setSelectedTab] = useState(1);
  const [updatedRecordId, setUpdatedRecordId] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderType, setLoaderType] = useState("");
  const [ApiError, setApiError] = useState("");
  const [values, setValues] = useState({
    title: "",
    category_id: "",
    subscription: "",
    language: "",
    is_free: "",
    reward_credits: "",
    enroll_credits: "",
    author_name: "",
    // program: "",
    pre_req_courses: [],
    payment_type: "",
    completion_duration: "",
    quiz_url: "",
    short_description: "",
    detail: "",
    banner: null,
    tags: [],
    learning_points: [],
    urls: [],
    documents: [],
    modules: [],
    completed_percentage: "",
    quiz_percentage: "",
    validity_period: "",
    validity_type: "",
    cert_description: "",
  });
  const [errors, setErrors] = useState({});
  const [rowItems, setRowItems] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVariant, setModalVariant] = useState("");
  const [modalType, setModalType] = useState("");

  const getCourseDetails = async () => {
    if (updatedRecordId) {
      setLoaderType("get_details");
      let result = await CallAPI(
        "GET_COURSE_DETAIL",
        null,
        updatedRecordId,
        setLoader,
        null,
        null
      );
      if (result.status) {
        let courseObj = result.data;
        let prevValues = {
          title: courseObj.title,
          subscription: courseObj.subscription ? courseObj.subscription.id : "",
          reward_credits: courseObj.reward_credits,
          enroll_credits: courseObj.enroll_credits,
          category_id: courseObj.category.id,
          language: courseObj.language,
          detail: courseObj.description,
          is_free: courseObj.is_free ? "1" : "0",
          short_description: courseObj.short_description,
          completion_duration: courseObj.completion_duration,
          pre_req_courses: courseObj.course_pre_requisites
            ? courseObj.course_pre_requisites.map((singlePreReq) => {
                return { id: singlePreReq.id, title: singlePreReq.title };
              })
            : [],
          quiz_url: courseObj.quiz_url,
          completed_percentage:
            courseObj.certificate_creteria.completed_percentage,
          quiz_percentage: courseObj.certificate_creteria.quiz_percentage,
          validity_period: courseObj.certificate_creteria.validity_period,
          validity_type: courseObj.certificate_creteria.validity_type,
          cert_description: courseObj.certificate_creteria.description,
          payment_type: courseObj.payment_type,
          author_name: courseObj.author_name,
          banner: courseObj.banner_image
            ? { id: courseObj.banner_image.id }
            : null,
          tags: courseObj.tags.map((singleTag) => {
            return { id: singleTag.id, title: singleTag.title };
          }),
          learning_points: courseObj.learning_points.map(
            (singleLearningPoint) => {
              return {
                id: singleLearningPoint.id,
                text: singleLearningPoint.title,
              };
            }
          ),
          urls: courseObj.urls.map((singleUrl) => {
            return {
              id: singleUrl.id,
              text: singleUrl.title,
            };
          }),
          modules: courseObj.course_modules.map((singleModule) => {
            return {
              id: singleModule.id,
              sequence: singleModule.sequence,
              title: singleModule.title,
            };
          }),
          documents: courseObj.documents.map((singleDocument) => {
            return {
              id: singleDocument.id,
              media_id: singleDocument.id,
              name: singleDocument.name,
              size: singleDocument.size,
            };
          }),
          course: courseObj,
        };
        setValues({
          ...prevValues,
        });
      }
    }
  };

  const getRequiredDataForForm = async () => {
    const details = {
      categories: [
        {
          value: "",
          displayValue: "Select",
        },
      ],
      programs: [
        {
          value: "",
          displayValue: "Select",
        },
      ],
      subscriptions: [
        {
          value: "",
          displayValue: "Select",
        },
      ],
      isFree: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "1",
          displayValue: "True",
        },
        {
          value: "0",
          displayValue: "False",
        },
      ],
      paymentType: [
        {
          value: "",
          displayValue: "Select",
        },
        {
          value: "PAY_AS_STRIPE",
          displayValue: "Pay via Stripe/PayStack",
        },
        {
          value: "PAY_AS_CREDITS",
          displayValue: "Pay Credits",
        },
        {
          value: "BOTH",
          displayValue: "BOTH",
        },
      ],
    };
    const categoriesList = await CallAPI(
      "FETCH_ALL_CATEGORIES",
      {},
      null,
      null,
      null,
      null
    );
    if (categoriesList.status) {
      const allCategories = categoriesList.data.categories.map(
        (singleCategory) => {
          return {
            value: singleCategory.id,
            displayValue: singleCategory.title,
          };
        }
      );
      details.categories = [...details.categories, ...allCategories];
    }
    const subscriptionPlanList = await CallAPI(
      "GET_ALL_SUBSCRIPTION_PLANS",
      {},
      null,
      null,
      null,
      null
    );
    if (subscriptionPlanList.status) {
      let allSubscriptionPlans = subscriptionPlanList.data.map(
        (singleSubscriptionPlan) => {
          if (singleSubscriptionPlan.type === "MONTHLY")
            return {
              value: singleSubscriptionPlan.id,
              displayValue: singleSubscriptionPlan.title,
            };
        }
      );
      allSubscriptionPlans = allSubscriptionPlans.filter(Boolean);
      details.subscriptions = [
        ...details.subscriptions,
        ...allSubscriptionPlans,
      ];
    }

    setListingDetails(details);
  };

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.update_id &&
      location.state.update_id !== ""
    )
      setUpdatedRecordId(location.state.update_id);

    getRequiredDataForForm();
  }, [location]);
  useEffect(() => {
    if (updatedRecordId !== "");
    getCourseDetails();
  }, [updatedRecordId]);

  const updateFormValues = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const previousClick = () => {
    if (selectedTab === 1) {
      navigate("/course");
    } else {
      setSelectedTab(selectedTab - 1);
    }
  };

  const selectOtherTab = async (tabToSelect) => {
    // let startTab = 1;
    // try {
    //   while (startTab < tabToSelect) {
    //     const errorValidatorSchema =
    //       startTab === 1
    //         ? BasicDetailsSchema
    //         : startTab === 2
    //         ? TagsLearnHighlightsSchema
    //         : startTab === 4
    //         ? NotesModulesSchema
    //         : startTab === 5
    //         ? CertificationSchema
    //         : null;
    //     if (errorValidatorSchema)
    //       await errorValidatorSchema.validate(values, { abortEarly: false });
    //     startTab++;
    //   }
    //   setSelectedTab(startTab);
    // } catch (error) {
    //   const schemaErrors = {};
    //   error.inner?.forEach((err) => {
    //     schemaErrors[err.path] = err.message;
    //   });
    //   setErrors(schemaErrors);
    //   setSelectedTab(startTab);
    // }
  };

  const nextClick = async () => {
    if (selectedTab === AddCourseTabs.length) {
      submitForm();
    } else {
      try {
        setErrors({});
        const errorValidatorSchema =
          selectedTab === 1
            ? BasicDetailsSchema
            : selectedTab === 2
            ? TagsLearnHighlightsSchema
            : selectedTab === 4
            ? NotesModulesSchema
            : selectedTab === 5
            ? CertificationSchema
            : null;
        if (errorValidatorSchema)
          await errorValidatorSchema.validate(values, { abortEarly: false });
        setSelectedTab(selectedTab + 1);
      } catch (error) {
        console.log("error", error);
        const schemaErrors = {};
        error.inner?.forEach((err) => {
          schemaErrors[err.path] = err.message;
        });
        setErrors(schemaErrors);
      }
    }
  };
  const submitForm = async () => {
    setLoaderType("submit_form");
    let bannerImage = null;
    if (values.banner && values.banner.id === undefined) {
      bannerImage = await UploadMedia(
        [values.banner],
        [
          {
            name: values.banner.path,
            size: values.banner.size.toString(),
            extension: values.banner.type,
          },
        ]
      );
      bannerImage = bannerImage[0];
    } else if (values.banner && values.banner.id) {
      bannerImage = values.banner.id;
    }

    let addedDocuments = [];
    if (values.documents.length > 0) {
      let files = [];
      let filesDetail = [];
      for (let index = 0; index < values.documents.length; index++) {
        if (
          values.documents[index] &&
          values.documents[index].id === undefined
        ) {
          files.push(values.documents[index]);
          filesDetail.push({
            name: values.documents[index].path,
            size: values.documents[index].size.toString(),
            extension: values.documents[index].type,
          });
        }
      }
      if (files.length > 0)
        addedDocuments = await UploadMedia(files, filesDetail);
    }

    const addedModules = getArrayDifference(
      values.modules,
      values?.course?.modules ? values.course.modules : []
    );

    const addedModulesList = addedModules.map((singleSelectedModule, index) => {
      return { id: singleSelectedModule.id, sequence: index + 1 };
    });

    const unitDetail = {
      title: values.title,
      category_id: values.category_id,
      subscription_id: values.subscription ? values.subscription : null,
      language: values.language,
      author_name: values.author_name,
      pre_req_courses: values.pre_req_courses.map((singleCourse, index) => {
        return {
          sequence: index + 1,
          id: singleCourse.version_id,
        };
      }),
      payment_type: values.payment_type,
      quiz_url: values.quiz_url,
      short_description: values.short_description,
      reward_credits: parseInt(values.reward_credits),
      enroll_credits: parseInt(values.enroll_credits),
      completion_duration: parseInt(values.completion_duration),
      completed_percentage: parseInt(values.completed_percentage),
      quiz_percentage: parseInt(values.quiz_percentage),
      validity_period: parseInt(values.validity_period),
      validity_type: values.validity_type,
      cert_description: values.cert_description,
      banner_image_id: bannerImage,
      description: values.detail,
      is_free: values.is_free === "1",
      tags: {
        added: values?.course?.tags
          ? getArrayDifference(values.tags, values.course.tags)
          : values.tags,
        deleted: values?.course?.tags
          ? getArrayDifference(values.course.tags, values.tags, "string", "id")
          : [],
      },
      learning_points: {
        added: getArrayDifference(
          values.learning_points,
          values?.course?.learning_points ? values.course.learning_points : [],
          "string",
          "text"
        ),
        deleted: values?.course?.learning_points
          ? getArrayDifference(
              values.course.learning_points,
              values.learning_points,
              "string",
              "id"
            )
          : [],
      },
      documents: {
        added: addedDocuments,
        deleted: values?.course?.documents
          ? getArrayDifference(
              values.course.documents,
              values.documents,
              "string",
              "id"
            )
          : [],
      },
      urls: {
        added: getArrayDifference(
          values.urls,
          values?.course?.urls ? values.course.urls : [],
          "string",
          "text"
        ),
        deleted: values?.course?.urls
          ? getArrayDifference(values.course.urls, values.urls, "string", "id")
          : [],
      },
      modules: addedModulesList,
    };
    console.log("unitDetail", unitDetail);
    const APIName =
      updatedRecordId && updatedRecordId !== ""
        ? "UPDATE_COURSE"
        : "ADD_COURSE";
    console.log("unitDetail", unitDetail);
    let result = await CallAPI(
      APIName,
      unitDetail,
      updatedRecordId && updatedRecordId !== "" ? updatedRecordId : null,
      setLoader,
      setApiError,
      null
    );
    if (result.status) {
      setIsModalOpen(true);
      setModalVariant("success");
      setModalType(
        updatedRecordId && updatedRecordId !== ""
          ? "recordUpdate"
          : "recordCreated"
      );
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/course");
  };
  function handleResize() {
    const itemsInSingleRow = handlePageResize();
    setRowItems(itemsInSingleRow);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {loader && loaderType === "get_details" && <LoaderComponent classes="" />}
      <div className="card">
        <div
          className="card-body custom-form"
          id="custom-form"
          style={{ "--noOfColumn": rowItems }}
        >
          {rowItems && (
            <>
              {ApiError && <ErrorComponent variant="error" text={ApiError} />}
              <TabsComponent
                tabs={AddCourseTabs}
                selectedTab={selectedTab}
                setSelectedTab={selectOtherTab}
              >
                <div className="mx-auto w-100 pt-15 pb-10 form-details">
                  {selectedTab === 1 ? (
                    <BasicDetails
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      otherDetails={{ ...listingDetails }}
                    />
                  ) : selectedTab === 2 ? (
                    <TagsLearnHighlights
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 3 ? (
                    <PreReqCourses
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                      updatedRecordId={updatedRecordId}
                    />
                  ) : selectedTab === 4 ? (
                    <NotesModules
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 5 ? (
                    <Certification
                      errors={errors}
                      values={values}
                      updateFormValues={updateFormValues}
                    />
                  ) : selectedTab === 6 ? (
                    <Preview values={values} />
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-auto d-flex justify-content-end gap-3 px-3">
                  <ButtonComponent
                    text={selectedTab === 1 ? "cancel" : "previous"}
                    onClick={previousClick}
                    variant="grey-1 w-auto"
                    loading={false}
                    disabled={false}
                  />
                  <ButtonComponent
                    text={
                      selectedTab === AddCourseTabs.length &&
                      updatedRecordId === ""
                        ? "submit"
                        : selectedTab === AddCourseTabs.length &&
                          updatedRecordId !== ""
                        ? "update"
                        : "next"
                    }
                    onClick={nextClick}
                    variant="blue-1 w-auto"
                    loading={loader && loaderType === "submit_form"}
                    disabled={loader && loaderType === "submit_form"}
                  />
                </div>
              </TabsComponent>
            </>
          )}
        </div>
      </div>
      {isModalOpen && (
        <CustomModal
          header={""}
          text={ModalButtons[modalType].text}
          buttons={ModalButtons[modalType].buttons}
          onClose={closeModal}
          variant={modalVariant}
          functionsList={{ closeModal }}
        />
      )}
    </>
  );
}

export default AddCourse;
