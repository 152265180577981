import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseImage from "assets/images/general_old/close.svg";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/ButtonComponent";
import RadioComponent from "components/RadioComponent";
import TextComponent from "components/TextComponent";
import { CallAPI } from "actions/General";
import { loadStripe } from "@stripe/stripe-js";

function PurchaseSubscription({ id, onClose }) {
  const { t } = useTranslation();

  const [loader, setLoader] = useState(false);
  const [discountType, setDiscountType] = useState("none");
  const [couponCode, setCouponCode] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [couponType, setCouponType] = useState("");

  const onChange = (name, value) => {
    setDiscountType(name);
    setCouponCode("");
    setDiscountPrice("");
  };
  const onChangeCoupon = (name, value) => {
    setCouponCode(value);
  };

  const stripePurchase = async () => {
    let bodyObj = {
      subscriptionId: id,
      discountType,
      couponCode,
      discountPrice,
      couponType,
    };
    const result = await CallAPI(
      "GET_STRIPE_INTENT",
      bodyObj,
      null,
      setLoader,
      null,
      null
    );
    console.log(result);
    if (result.status) {
      const stripePromise = await loadStripe(result.data.stripe_public_key);
      stripePromise.redirectToCheckout({
        sessionId: result.data.sessionId,
      });
    }
  };
  const payStackPurchase = () => {};
  const applyDiscount = () => {
    const discountPrice = couponCode === "fixed" ? 3 : 10;
    const couponType = couponCode === "fixed" ? "fixed" : "percentage";
    setDiscountPrice(discountPrice);
    setCouponType(couponType);
  };
  return (
    <div className={`custom-modal subscription`}>
      <div className="detail">
        <img className="close" src={CloseImage} onClick={onClose} alt="close" />
        <span className="header">{t("modal.purchase_subscription")}</span>
        <div className="items">
          {/* <div className="item">
            <RadioComponent
              name={"coupon_code"}
              label={"apply_coupon_code"}
              value={discountType === "coupon_code"}
              onChange={onChange}
              classes={{}}
              error=""
            />
            {discountType === "coupon_code" && (
              <div className="code">
                <TextComponent
                  name="couponCode"
                  value={couponCode}
                  setValue={onChangeCoupon}
                  label=""
                  placeholder="coupon_code"
                  classes={{ fieldClass: "small" }}
                  error=""
                />
                <div>
                  <ButtonComponent
                    text="apply"
                    variant="blue-1 w-auto"
                    onClick={applyDiscount}
                    loading={false}
                    disabled={false}
                    classes={{}}
                  />
                </div>
              </div>
            )}
          </div> */}
          {/* <div className="item">
            <RadioComponent
              name={"gift_code"}
              label={"apply_gift_card"}
              value={discountType === "gift_code"}
              onChange={onChange}
              classes={{}}
              error=""
            />
            {discountType === "gift_code" && (
              <div className="code">
                <TextComponent
                  name="couponCode"
                  value={couponCode}
                  setValue={onChange}
                  label=""
                  placeholder="gift_card"
                  classes={{ fieldClass: "small" }}
                  error=""
                />
                <div>
                  <ButtonComponent
                    text="apply"
                    variant="blue-1 w-auto"
                    onClick={applyDiscount}
                    loading={false}
                    disabled={false}
                    classes={{}}
                  />
                </div>
              </div>
            )}
          </div> */}
          {/* <div className="item">
            <RadioComponent
              name={"none"}
              label={"apply_none"}
              value={discountType === "none"}
              onChange={onChange}
              classes={{}}
              error=""
            />
          </div> */}
        </div>
        <div className="d-flex gap-3 justify-content-center">
          <div>
            <ButtonComponent
              text={"stripe_purchase"}
              variant={"orange-1"}
              onClick={stripePurchase}
              loading={loader}
              disabled={loader}
            />
          </div>
          {/* <div>
            <ButtonComponent
              text={"pay_stack_purchase"}
              variant={"blue-1"}
              onClick={payStackPurchase}
              loading={false}
              disabled={false}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
PurchaseSubscription.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default PurchaseSubscription;
