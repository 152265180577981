import React from "react";
import PropTypes from "prop-types";
import SelectComponent from "components/SelectComponent";
import TextAreaComponent from "components/TextAreaComponent";
import TextComponent from "components/TextComponent";
import SingleFileDropZoneComponent from "components/SingleFileDropZoneComponent";
import TextAreaSimpleComponent from "components/TextAreaSimpleComponent";

function BasicDetails({ values, errors, updateFormValues, otherDetails }) {
  return (
    <>
      <div className="mb-4 box-width-flex">
        <div>
          <TextComponent
            name={"title"}
            label={"enter_title"}
            placeholder={"title"}
            value={values.title}
            setValue={updateFormValues}
            classes={""}
            error={errors["title"] ?? ""}
            required={true}
          />
          <SelectComponent
            name={"category_id"}
            label={"select_category"}
            value={values.category_id}
            error={errors["category_id"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.categories}
            required={true}
          />
          <SelectComponent
            name={"subscription"}
            label={"select_subscription"}
            value={values.subscription}
            error={errors["subscription"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.subscriptions}
            required={false}
          />
          <TextComponent
            name={"language"}
            label={"language"}
            placeholder={"language"}
            value={values.language}
            setValue={updateFormValues}
            classes={""}
            error={errors["language"] ?? ""}
            required={true}
          />
          <TextComponent
            name={"enroll_credits"}
            label={"enroll_credits"}
            placeholder={"enroll_credits"}
            value={values.enroll_credits}
            setValue={updateFormValues}
            classes={""}
            error={errors["enroll_credits"] ?? ""}
            type="number"
            required={true}
          />
          <TextComponent
            name={"reward_credits"}
            label={"reward_credits"}
            placeholder={"reward_credits"}
            value={values.reward_credits}
            setValue={updateFormValues}
            classes={""}
            error={errors["reward_credits"] ?? ""}
            type="number"
            required={true}
          />
          <SelectComponent
            name={"is_free"}
            label={"is_free"}
            value={values.is_free}
            error={errors["is_free"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.isFree}
            required={true}
          />
          <TextComponent
            name={"author_name"}
            label={"author_name"}
            placeholder={"author_name"}
            value={values.author_name}
            setValue={updateFormValues}
            classes={""}
            error={errors["author_name"] ?? ""}
            required={true}
          />
          {/* <SelectComponent
            name={"program"}
            label={"program"}
            value={values.program}
            error={errors["program"] ?? ""}
            classes={{}}
            onChange={updateFormValues}
            optionsList={otherDetails.programs}
          /> */}
          <SelectComponent
            name={"payment_type"}
            label={"payment_type"}
            value={values.payment_type}
            error={errors["payment_type"] ?? ""}
            classes={""}
            onChange={updateFormValues}
            optionsList={otherDetails.paymentType}
            required={true}
          />
          <TextComponent
            name={"completion_duration"}
            label={"completion_duration"}
            placeholder={"completion_duration"}
            value={values.completion_duration}
            setValue={updateFormValues}
            classes={""}
            error={errors["completion_duration"] ?? ""}
            type="number"
            required={true}
          />
          <TextComponent
            name={"quiz_url"}
            label={"quiz_url"}
            placeholder={"quiz_url"}
            value={values.quiz_url}
            setValue={updateFormValues}
            classes={""}
            error={errors["quiz_url"] ?? ""}
            required={true}
          />
        </div>

        <div>
          <SingleFileDropZoneComponent
            name={"banner"}
            label={"banner_image"}
            value={values.banner}
            setValue={updateFormValues}
            classes={"half"}
            isMultipleAllowed={false}
            type={"image"}
            required={false}
          />
          <TextAreaSimpleComponent
            name={"short_description"}
            label={"short_description"}
            placeholder={"short_description"}
            value={values.short_description}
            setValue={updateFormValues}
            classes={"half"}
            error={errors["short_description"] ?? ""}
            type={"image"}
            required={true}
          />
        </div>
        <div>
          <TextAreaComponent
            name={"detail"}
            label={"description"}
            placeholder={"description"}
            value={values.detail}
            error={errors["detail"] ?? ""}
            setValue={updateFormValues}
            classes={"w-100"}
            required={true}
          />
        </div>
      </div>
    </>
  );
}
BasicDetails.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  updateFormValues: PropTypes.func.isRequired,
};
export default BasicDetails;
