import * as yup from "yup";
export const BasicDetailsSchema = yup.object({
  title: yup.string().required("Title is required"),
  teacher: yup.string().required("Teacher is required"),
  auto_generate_link: yup
    .string()
    .required("Select Auto generated meeting link"),
  link_setting: yup.string().required("Select Link Setting"),
  auto_upload_session: yup.string().required("Select Auto Upload Session"),
  type: yup.string().required("Select Type"),
  program_id: yup.string().when("type", {
    is: "PROGRAM",
    then: (schema) => schema.required("Select Program"),
    otherwise: (schema) => schema.notRequired(),
  }),
  course_id: yup.string().when("type", {
    is: "PROGRAM",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("Select Course"),
  }),
  description: yup.string().required("Description is required"),
});
export const AccessSchema = yup.object({
  department: yup.number().required("Select Department"),
  role: yup.number().required("Select Role"),
  profile: yup.number().required("Select Profile"),
});
export const ScheduleSchema = yup.object({
  year: yup.number().required("Select Year"),
  season_id: yup.string().required("Select Season"),
  schedule_date: yup.string().required("Select Schedule Date"),
  duration: yup.number().required("Duration is required"),
  duration_type: yup.string().required("Duration Type is required"),
  enrollment_end_date: yup.string().required("Select Enrollment End Date"),
  courses_schedules: yup
    .array()
    .of(
      yup.object().shape({
        course_id: yup.string().required("Course ID is required"),
        schedule_id: yup.string().required("Schedule ID is required"),
      })
    )
    .when("$type", (type, schema) =>
      type === "PROGRAM"
        ? schema.required("Courses schedules are required when type is Program")
        : schema.notRequired()
    ),
});
